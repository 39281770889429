.footer {
    width: 100%;
    padding: 0.4em 0;
    color: #18453b;
    @media screen and (max-width: 768px) {
        margin-bottom: 50px;
    }
    &__standard {
        margin-top: 2em;
        @media screen and (max-width: 640px) {
            margin-top: 1em;
        }
    }
    &__info {
        display: inline-block;
		margin-bottom: 1.25em;
		margin-top:-8px;
        &__links {
            // row off main footer links
            margin: 0 0 1px;
            line-height: 0.5;
            padding: 0 0 1px;
            border-bottom: 1px dotted grey;
            @media screen and (max-width: 722px) {
                line-height: 1.7;
            }
            @media screen and (max-width: 640px) {
                line-height: 2.1;
                text-align: center;
            }
            li {
                display: inline-block;
                font-size: 0.8rem;
                @media screen and (max-width: 640px) {
                    display: block;
                }
                &:first-child {
                    padding: 0;
                }
                &:last-child a {
                    border-right: none;
                }
                a {
                    text-decoration: none;
                    padding-right: 8px;
                    margin-right: 4px;
                    color: #18453b;
                    border-right: 1px solid #18453b;
                    @media screen and (max-width: 640px) {
                        border-right: none;
                        padding: 4px 8px;
                        margin-right: 0;
                    }
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &__copyright,
        &__msu {
            // global footer calls-to-action
            font-size: 0.6rem;
            padding-top: 0;
            height: 12px;
            margin: 0 0 4px;
            padding: 0;
            color:#18453b;
            li {
                margin-bottom: 0;
                line-height: 10px;
                display: inline;
                border-right: 1px solid #999;
                padding: 0 8px 0 4px;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    border-right: 0;
                }
                a {
                    text-decoration: none;
                    color: #4c7e00;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            img.print-msuwordmark {
                display: none;
            }
            @media screen and (max-width:990px) {
                height: auto;
            }
        }
    }
    &__wordmark {
        display: inline-block;
        float: left !important;
        width: 235px;
        margin: 0 20px 10px 0;
    }
}

.back-top {
    display: block;
    background: #444444;
    border-radius: 5px;
    font-size: 1.6em;
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 15px;
    padding: 5px 25px 10px;
    color: #ffffff;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    opacity: 0;
    visibility: hidden;
    &.visible {
    	opacity: .50;
    	bottom: 10px;
    	visibility: visible;
	}
	&:hover,&:focus{
		opacity: .80;
		background: #222222;
	    color: #ffffff;
	}
}