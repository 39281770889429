html, body{
	font-size:100%;
}

.main{
	padding-top:0px;
	padding-left:0px;
	padding-right:0px;
	padding-bottom:0px;
}

.page-content{
	padding-top: 15px;
	padding-bottom: 15px;
	.featured-image{
		max-width:100%;
		height:auto;
		margin-bottom:15px;
	}
	.page-title{
		color:$primary-color;
		text-transform: uppercase;;
		font-weight: 700;
		margin-top:0px;
	}
	.page-subhead{
		font-weight:bold;
		font-size:1.6em;
	}
}

.page-post{
	.page-content{
		.page-meta{
			margin-top:20px;
			font-size:0.8em;
			.author{
				font-weight:bold;
			}
			.auth-name{}
			.updated{
				font-weight:bold;
			}
			time{}
		}
		.page-footer{
			margin-top:20px;
			.footer-share{
				.share{
					font-weight:bold;
				}
			}
		}
	}
}

.page-examples{
	.page-content{
		padding-left:15px;
		padding-right:15px;
		padding-bottom:15px;
	}
}

.page-sidebar{
	.sidebar-section{
		border-top:1px solid $gainsboro;
		@media only screen and (min-width: 768px) {
			border-top:0px;
		}
		padding-top:15px;
		padding-bottom:15px;
	}
	.sidebar-title{
		padding:10px;
		margin:0px;
	}
	.sidebar-nav, .sidebar-nav > li > ul{
		list-style:none;
		padding:0px;
	}
	.sidebar-nav > li {
		a{
			display:block;
			padding:10px;
			font-size:1.2em;
			font-weight:bold;
			line-height:1em;
			color:$oil;
			&:hover,&:focus{
				color:$primary-color;
				background: $gainsboro;
				text-decoration: none;
			}
		}
	}
	.sidebar-nav > li.active {
		background: $silver;
		ul {
			background: $silver;
			> li.active a{
				background:$gainsboro;
				color:$primary-color;
			}
		}
		> a {
			background:$gainsboro;
			color:$primary-color;
		}
	}
	.sidebar-nav > li > ul{
		display:none;
	}
	.sidebar-nav > li.active > ul{
		display:inline;
	}
	.sidebar-nav > li > ul > li {
		a{	
			display: block;
			padding:10px 10px 10px 30px;
			font-size:1.0em;
			line-height:1em;
			color:$oil;
			&:hover,&:focus{
				color:$primary-color;
				background: $gainsboro;
				text-decoration: none;
			}
		}
	}
}
.fullscreen{
	// height:80vh;
	// min-height:80vh;
	// max-height:80vh;
	// overflow:hidden;
}