body{
	line-height: 1.6em;
	// background: $color-bg-01;
}

a::-moz-focus-inner {
  border: 0;
}
a:focus {
    outline: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    color:$charcoal;
}

h1{
	font-size:2em;
}
p, ul, ol{
	margin-bottom:2em;
}
p, li{
	font-size:1.2em;
	line-height:2em;
	color:$oil;
	font-weight:400;
}
.lead{
	color:$charcoal;
	font-size:1.6em;
	line-height:1.8em;
	font-weight:400;
}